
import { LANG_CHANGED, SHOW_DISCLAIMER } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { langChanged, updateObject } from 'src/store/reducers/commons';


const getDefaultState = () => ({
    labels: getLabels(),
});


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case LANG_CHANGED: return langChanged(state, action);
        case SHOW_DISCLAIMER: return updateObject(state, { showDisclaimer: true });
        default: return state;
    }
};
