
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_EVENTS,
    DATA_TYPE_SPEAKERS,
    getSpeakerTitle,
    getSpeakerSubtitle,
} from 'data/config/dataConfig';

import { SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailCountry      from 'src/components/detail-country/DetailCountry';
import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocial       from 'src/components/detail-social/DetailSocial';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import Description        from 'src/components/fiche/Description';
// import PhoneRow           from 'src/components/fiche/PhoneRow';
// import EmailRow           from 'src/components/fiche/EmailRow';
// import WebsiteRow         from 'src/components/fiche/WebsiteRow';
// import ShareButton        from 'src/components/share-button/ShareButton';


class SpeakerContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_SPEAKERS,
            this.props.isFavorite
        );
    }

    showEvents = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_EVENTS],
            DATA_TYPE_EVENTS,
            this.props.item.id,
            DATA_TYPE_SPEAKERS);
    }

    render() {
        let lump = this.props.item.lump;

        const
            title    = getSpeakerTitle(this.props.item),
            subTitle = getSpeakerSubtitle(this.props.item),
            logoUrl  = this.props.item.photo,
            description = this.props.item.biography;

        return (
            <div className="fiche speaker content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                    title={title}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels} >

                    {/* <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={SPEAKER_PAGE_KEY} /> */}

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>
                    <div className="content">
                        <div className="all-informations content-font">

                            <Description
                                logoFileName={logoUrl}
                                description={description}
                                isLogoFullWidth={this.props.isLogoFullWidth} />

                            {/* events */}
                            <DetailEvents
                                items={this.props.item.references.events}
                                labels={this.props.labels}
                                onClick={this.showEvents} />

                            {/* contacts bar */}
                            { (this.props.item.website || this.props.item.email || this.props.item.phone) &&
                                <div className="fiche-separator">{this.props.labels.common.information}</div>
                            }

                            <DetailCountry country={this.props.item.country} />

                            <DetailSocial data={this.props.item.lump.social} actions={this.props.actions}/>

                            {/* <PhoneRow phone={this.props.item.phone} />

                            <WebsiteRow
                                website={this.props.item.website}
                                actions={this.props.actions} />

                            <EmailRow email={this.props.item.email} /> */}

                        </div>
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }
}

SpeakerContent.propTypes = {
    item        : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isFavorite  : PropTypes.bool,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default SpeakerContent;
