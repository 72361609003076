module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: ''
    },
    id: ''
  },
  web: {
    deploy: {
      host: 'kim1',
      user: 'www',
      path: '/home/www/mobigeo/'
    }
  }
});