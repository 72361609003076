module.exports = {
  name: 'aecongress19',
  client: 'AUTISM EUROPE CONGREE 2019',
  description: "Application du congrès Autism Europe 2019",
  pluginId: 'mobigeo-plugin-aecongress',
  version: '1.0.0',
  validLangs: ['en', 'fr'],
  native: {
    name: {},
    // by env
    version: '10',
    id: '',
    // by env
    // 'ionic/www', 'simple', 'test', 'demo', 'app-react'
    app: 'app-react',
    backgroundColor: '0xffffff'
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'oR9voo2k'
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'poi.states'
  features: [],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ['source', 'exports'],
  cordova: false,
  crypto: false,
  cordova_plugins: {
    mobigeo_plugin_dependencies: [],
    app_dependencies: [{
      id: 'cordova-plugin-file'
    }, {
      id: 'cordova-plugin-file-transfer'
    }, {
      id: 'cordova-plugin-whitelist'
    }, {
      id: 'cordova-plugin-inappbrowser@3.0'
    }, {
      id: 'cordova-plugin-splashscreen'
    }, {
      id: 'pushwoosh-cordova-plugin'
    }]
  },
  geoGroup: {},
  web: {}
};