import { WINDOW_RESIZED } from "src/store/actionTypes"

import { windowResized } from "src/store/reducers/commons"

const getDefaultState = () => Object.assign({}, {}, {})

export default (state = getDefaultState(), action) => {
	switch (action.type) {
		case WINDOW_RESIZED:
			return windowResized(state, action)
		default:
			return state
	}
}
