
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_EVENTS,
    DATA_TYPE_PAPERS,
    DATA_TYPE_PAPER_CATEGORIES
} from 'data/config/dataConfig';

import { LIST_PAGE_KEY, PAPER_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailDocument     from 'src/components/detail-document/DetailDocument';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import ShareButton        from 'src/components/share-button/ShareButton';
import LinkButton         from 'src/components/link-button/LinkButton';


class PaperContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_PAPERS,
            this.props.isFavorite
        );
    }

    showEvents = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_EVENTS],
            DATA_TYPE_EVENTS,
            this.props.item.id,
            DATA_TYPE_PAPERS);
    }

    onClickOnCategory = event => {

        let paperCatId = parseInt(event.target.dataset.catId, 10),
            paperCat;

        // Get the exhibitor category
        let paperCats = this.props.item.categories;
        for (let i=0; i<paperCats.length && !paperCat; i++) {
            if (paperCats[i].id === paperCatId) {
                paperCat = paperCats[i];
            }
        }
        if (!paperCat) {
            // Should never happen
            return;
        }

        let paperCatLump = paperCat.lump;

        if (Array.isArray(paperCatLump.papers) && paperCatLump.papers.length > 0) {
            // Display content
            this.props.actions.navigate(LIST_PAGE_KEY, {
                locateAll: false,
                inputs: [{
                    parentId  : paperCatId,
                    parentType: DATA_TYPE_PAPER_CATEGORIES,
                    dataType  : DATA_TYPE_PAPERS,
                }],
            });
        }
        else {
            // Display sub-categories
            this.props.actions.navigate(LIST_PAGE_KEY, {
                locateAll: false,
                inputs: [{
                    id: paperCat.id,
                    dataType: DATA_TYPE_PAPER_CATEGORIES,
                }],
            });
        }
    }

    /**
     * All fields are not always exported in data json file,
     * so let's check field presence, then its value
     * @param {number} fieldIndex
     */
    dangerouslySetHtml(fieldIndex) {
        if (typeof fieldIndex === 'number' && this.props.item[fieldIndex]) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.item[fieldIndex] }} />;
        }
        return null;
    }

    render() {
        const
            title       = this.props.item.title,
            description = this.props.item.description,
            lump        = this.props.item.lump,
            code        = this.props.item.lump.code || title;

        return (
            <div className="fiche paper content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                    title={code}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels}>

                    <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={PAPER_PAGE_KEY} />

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>

                    <div className="content">
                        <div className="all-informations content-font">
                            { this.props.item.lump.code &&
                                <div className="paper-title">
                                    <h2>{title}</h2>
                                    <span className="paper-authors">{this.props.labels.paper.authors}</span>
                                </div>
                            }
                            <div className="paper-text">
                                <p dangerouslySetInnerHTML={{ __html: description }} />
                            </div>

                            {/* Counters */}
                            <div>

                                {/* paper link button */}
                                { lump.document &&
                                    <DetailDocument
                                        labels={this.props.labels}
                                        actions={this.props.actions}
                                        document={lump.document}
                                    />
                                }

                                {/* events */}
                                <DetailEvents
                                    items={this.props.item.references.events}
                                    labels={this.props.labels}
                                    onClick={this.showEvents} />

                            </div>

                            { lump.button &&
                                <LinkButton
                                    config={lump.button}
                                    actions={this.props.actions}
                                />
                            }

                            {/* categories */}
                            { this.props.item.categories && this.props.item.categories.length > 0 &&
                                <div>
                                    <div className="fiche-separator">{this.props.labels.paper.categories}</div>
                                    <div className="activity free-row">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-tag" />
                                                </div>
                                            </div>
                                            <div className="prop-right activites flex-ellipsis">
                                                { this.props.item.categories.map(paperCat => (
                                                    <div key={paperCat.id}
                                                         data-cat-id={paperCat.id}
                                                         onClick={this.onClickOnCategory}
                                                         className="activities-content color-link-blue">

                                                        <span>{ paperCat.title }</span>

                                                        <div className="prop-right-fleche">
                                                            <span className="fa fa-chevron-right fleche" />
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div> {/* end of .allInformations */}
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }

};

PaperContent.propTypes = {
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default PaperContent;
