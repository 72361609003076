
import getToolbarConfig from 'data/config/toolbarConfig';
import { isLogoFullWidth } from 'data/config/ficheConfig';
import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';

import STATUS from 'src/store/fetchStatuses';

import { getAdConfig }    from 'src/core/config-json/ConfigJsonManager';
import { getPollConfig }  from 'src/core/polls/PollManager';
import { isFavorite }     from 'src/core/favorites/Favorites';
import { getOrientation } from 'src/core/util/DomTools';
import { getPlaceholder } from 'src/core/search/searchUtil';

import { get as getLabels }  from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';

/**
 * Encapsulate the idea of passing a new object as the first parameter
 * to Object.assign to ensure we correctly copy data instead of mutating
 *
 * @param  {object} oldObject
 * @param  {object} newValues
 * @return {object}
 */
export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);


export function getPageCommonDefaultState(pageKey) {
    const profile = getProfile();

    let state = Object.assign({}, getToolbarConfig(profile, pageKey));

    state.labels = getLabels();
    state.profile = getProfile();
    state.isMenuOpen = false;
    state.contributions = null;
    state.isLocationEnabled = false;
    state.isPMREnabled = false;

    state.favorites= {};
    state.favIconDisabled = DISABLE_FAVORITE_ICON === true;

    if (pageKey) {
        state.isLogoFullWidth = isLogoFullWidth(pageKey);
    }

    return state;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualDataType
 * @return {object}
 */
export const toggleFavorite = (state, action, contextualDataType) => {
    let update = {}
    if (action.favListUpdated
            && action.dataType === contextualDataType
            && typeof state.id !== 'undefined'
            && state.id !== null
            && state.id == action.id) {

        update = {
            isFavorite: isFavorite(state.id, contextualDataType)
        }
    }
    
    return updateObject(state, {
        favorites: action.favorites,
        ...update
    });
};

export const setIsFavoriteFalse = state => updateObject(state, {
    isFavorite: false,
    favorites: {}
});

/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} pageKey
 * @param  {boolean} shouldIncludeAdconfig
 * @return {object}
 */
export const profileChanged = (state, action, pageKey, shouldIncludeAdconfig) => {
    let newValues = { profile: action.profile };

    // Update toolbar config
    if (typeof pageKey === 'string') {
        newValues = Object.assign(newValues, getToolbarConfig(action.profile, pageKey));
    }

    // Include ad config
    if (shouldIncludeAdconfig) {
        newValues.adConfig = getAdConfig(action.profile);
    }

    return updateObject(state, newValues);
};


/**
 * Return true if one of `usedTables` is listed in `updatedTables`
 * @param  {array} updatedTables
 * @param  {array} usedTables
 * @return {boolean}
 */
export const hasATableBeenUpdated = (updatedTables, usedTables) => {

    // if value is null, we safely choose to consider that data could have been updated
    if (!updatedTables) {
        return true;
    }
    let match = false;

    for (let i=0; match === false && i<updatedTables.length; i++) {
        match = usedTables.indexOf(updatedTables[i]) !== -1;
    }
    return match;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {array} usedTables
 * @return {object}
 */
export const dataUpdated = (state, action, usedTables) => {
    if (hasATableBeenUpdated(action.tables, usedTables) === true) {
        return updateObject(state, { shouldFetch: true });
    }
    return state;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualDataType
 * @return {object}
 */
export const itemFetched = (state, action, contextualDataType) => {
    if (action.dataType === contextualDataType) {
        return updateObject(state, {
            id          : action.id,
            item        : action.item,
            isPending   : action.status === STATUS.PENDING,
            itemNotFound: action.status === STATUS.NO_RESULT,
            isFavorite  : action.isFavorite,
            shouldFetch : false,
        });
    }
    return state;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const langChanged = (state, action) => updateObject(state, { labels: action.labels });


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualPageKey
 * @return {object}
 */
export const updatePageState = (state, action, contextualPageKey) => {
    if (action.pageKey === contextualPageKey) {
        return updateObject(state, action.props);
    }
    return state;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualPageKey
 * @return {object}
 */
export const toggleMenu = (state, action, contextualPageKey) => {
    if (action.pageKey === contextualPageKey) {
        return updateObject(state, { isMenuOpen: action.isOpen });
    }
    return state;
};


/**
 * Common TOGGLE_LOCATION_STATUS reducer function
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const toggleLocationStatus = (state, action) => updateObject(state, { isLocationEnabled: action.value === true });


/**
 * Common TOGGLE_LOCATION_CAPABILITY_STATUS reducer function
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const toggleLocationCapabilityStatus = (state, action) => updateObject(state, { isLocationReady: action.value === true });


/**
 * Common TOGGLE_PMR_STATUS reducer function
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const togglePMRStatus = (state, action) => updateObject(state, { isPMREnabled: action.value === true });

/**
 * config.json file has been loaded and parsed
 * @param  {object} state
 * @return {object}
 */
export const configJsonLoaded = (state) => updateObject(state, { adConfig: getAdConfig(state.profile) });

/**
 * Poll config file has been loaded and parsed
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const pollConfigLoaded = (state, action) => updateObject(state, { pollConfig: getPollConfig(state.profile) });


export const windowResized = (state, action) => updateObject(state, {
    lastResize : action.timestamp,
    orientation: getOrientation(),
});


export const setFavoritesSynchronizationStatus = (state, action) => updateObject(state, {
    synchroFavBtnStatus: action.status,
});


export const setSearchPlaceHolder = (state, action) => (
    updateObject(state, {
        placeholder: getPlaceholder(),
    })
);

export const searchPerformed = (state, action) => {
    const parseItems = (items) => {
        const parsedItems = {};
        // loop on data types
        Object.keys(items).forEach(dataType => {
            const _items = items[dataType]
            if (_items && Array.isArray(_items) && _items.length > 0) {
                parsedItems[dataType] = _items
            }
        });
        return parsedItems;
    }
    return updateObject(state, {
        searchResults: parseItems(action.results),
        contextualSearch: action.contextualSearch
    })
}

export const clearSearchResults = state => (
    updateObject(state, {
        searchResults: null,
    })
)

export const fetchFavorites = (state, action) => (
    updateObject(state, {
        favorites: action.favorites
    })
)

export const toggleFavorites = (state, action) => {
    if (action.favListUpdated === true) {
        return updateObject(state, { favorites: action.favorites });
    }
    return state;
};