import { SEARCH_PERFORMED, CLEAR_SEARCH_RESULTS } from "src/store/actionTypes"

import { searchPerformed, clearSearchResults } from "src/store/reducers/commons"

const getDefaultState = () => Object.assign({}, {}, {})

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case SEARCH_PERFORMED:
      return searchPerformed(state, action)
    case CLEAR_SEARCH_RESULTS:
      return clearSearchResults(state)
    default:
      return state
  }
}
