var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'aecongress-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/eventime/aecongress/aecongress2019/aecongress-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/eventime/aecongress/aecongress2019/aecongress-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-24',
  projectId: '',
  // FCM sender id
  appId: '',
  // pushwoosh appid
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  }
});