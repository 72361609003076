import { PAPER_PAGE_KEY } from 'src/pages/pagesKeys.js';

import PaperPage        from 'src/pages/paper/PaperPage';
import paperPageReducer from 'src/pages/paper/paperPageReducer';

export default {
    key      : PAPER_PAGE_KEY,
    path     : '/paper',
    component: PaperPage,
    elId     : 'paper-page',
    getReduxReducer: (state, action) => paperPageReducer(state[PAPER_PAGE_KEY], action),
};
