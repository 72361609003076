
import React, { PureComponent  } from 'react';
import PropTypes from 'prop-types';
import { AlertDialog } from 'react-onsenui';

import config from 'data/config/config';

import Button         from 'src/components/button';
import List           from 'src/components/list/List';
import TypeBar        from 'src/components/type-bar/TypeBar';
import NoResult       from 'src/components/no-result/NoResult';
import AllOnMapButton from 'src/components/all-on-map-button/AllOnMapButton';
import {stripToIds}   from 'src/pages/map/mapUtil';
import {
    isUnauthorizedMode,
    downloadFavorites
}   from 'src/core/favorites/SynchronizedFavoritesService';
import { iosHackScrollTo0 } from 'src/core/util/browser';

import {
    STATUS as SYNCHRO_FAV_STATUS,
    codePersistence
} from 'src/core/favorites/SynchronizedFavoritesService';

import '../../components-standalone/genericModal.scss';

import { MAP_TYPE } from 'data/config/mapConfig';

const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation: 'default', // or 'none', no other choice sadly
    className: 'project-font'
};

class FavoritesContent extends PureComponent {

    state = {
        codeValue: '',
        formNotice: ''
    }

    codeDialogProps = () => Object.assign({}, DEFAULT_DIALOG_PROPS, {
        isOpen  : this.props.isCodeDialogOpen,
        onCancel: this.props.actions.hideFavoritesCodeDialog
    })

    setCodeField = el => {
        this.codeField = el;
    }

    componentWillUpdate(nextProps) {
        const { code, codeSyncStep, isCodeDialogOpen, temporaryCode, labels } = this.props

        if ((nextProps.isCodeDialogOpen && !isCodeDialogOpen)
            || (nextProps.code !== code)
            || (nextProps.codeSyncStep !== codeSyncStep
            || (nextProps.temporaryCode !== temporaryCode))
        ) {
            const storedCode = codePersistence.get()
            this.setState({ codeValue: nextProps.temporaryCode || nextProps.code || storedCode || ''})
        }
    }

    renderCodeDialog = () => {
        const { labels, actions, codeSyncStep, temporaryCode } = this.props
        const { codeValue } = this.state

        return (
            <div id="favorites-code-dialog" className="generic-modal">

                <span
                    className="close-favorites-code-dialog fa fa-times"
                    onClick={actions.hideFavoritesCodeDialog}
                />

                { codeSyncStep === 'choice' &&
                    <div className="wrapper">
                        <div className="header title-font">{labels.synchroFavs.syncCode}</div>
                        <div className="body">
                            <div>
                                <div
                                    className="generic-modal-btn choice-btn"
                                    onClick={() => actions.setFavoritesCodeSyncStep('form')}
                                >{labels.synchroFavs.haveCode}</div>
                            </div>
                            <div>
                                <div
                                    className="generic-modal-btn choice-btn"
                                    onClick={() => {
                                        actions.fetchFavoritesCode()
                                        actions.setFavoritesCodeSyncStep('fetching')
                                    }}
                                >{labels.synchroFavs.noCode}</div>
                            </div>
                        </div>
                    </div>
                }
    
                { codeSyncStep === 'fetching' &&
                    <div className="wrapper">
                        <div className="header title-font">{labels.synchroFavs.loadingCode}</div>
                        <div className="body">
                            <span className="fa fa-spinner fa-spin fa-3x fa-fw" />
                        </div>
                    </div>
                }

                { codeSyncStep === 'form' && temporaryCode && 
                    <div className="wrapper">
                        <div className="header title-font">{labels.synchroFavs.syncCode}</div>
                        <div className="body">
                            
                            <div className="notice">{labels.synchroFavs.codeCreatedNotice}</div>

                            <div className="code-created">{temporaryCode}</div>
                        </div>
                        <div className="footer generic-modal-btn-container content-font">
                            <div className="generic-modal-btn" onClick={actions.hideFavoritesCodeDialog}>{labels.common.cancel}</div>
                            <div
                                className='generic-modal-btn cta-modal-btn'
                                onClick={this.submitTemporaryCode}>{labels.common.ok}</div>
                        </div>
                    </div>
                }

                { codeSyncStep === 'form' && !temporaryCode &&
                    <div className="wrapper">
                        <div className="header title-font">{labels.synchroFavs.syncCode}</div>
                        <div className="body">
                            
                            <div className="notice">{labels.synchroFavs.enterCodeNotice}</div>

                            <form
                                onSubmit={
                                    e => {
                                        e.preventDefault();
                                        this.handleCodeSubmission()
                                    }
                                }
                            >
                                <div className="form-row">
                                    <input
                                        className="generic-modal-input"
                                        placeholder={labels.synchroFavs.codePlaceholder}
                                        value={codeValue}
                                        onChange={this.handleCodeChange}
                                        ref={this.setCodeField}
                                        onBlur={iosHackScrollTo0}
                                        name="code" />
                                </div>
                            </form>

                            <div className="validation">{labels.synchroFavs.onlyDigits}</div>
                        </div>
                        <div className="footer generic-modal-btn-container content-font">
                            <div className="generic-modal-btn" onClick={actions.hideFavoritesCodeDialog}>{labels.common.cancel}</div>
                            <div
                                className={`generic-modal-btn cta-modal-btn ${!codeValue ? 'active': ''}`}
                                onClick={this.handleCodeSubmission}>{labels.common.ok}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    handleCodeChange = event => {
        if (/^\d+$/.test(event.target.value) || event.target.value === '') {
            this.setState({ codeValue: event.target.value });
        }
    }

    handleCodeSubmission = e => {
        const { actions } = this.props
        const { codeValue } = this.state
        if (e && e.preventDefault && typeof e.preventDefault === 'function') e.preventDefault()

        if (!codeValue) return;

        actions.hideFavoritesCodeDialog();
        actions.setCodeIdentification(codeValue)
        actions.syncWithFavoritesCode();
        this.setState({ codeValue: '' })
    }

    submitTemporaryCode = () => {
        const { actions, temporaryCode } = this.props
        this.setState({ codeValue: '' })
        actions.hideFavoritesCodeDialog();
        actions.setCodeIdentification(temporaryCode)
        actions.syncWithFavoritesCode();
    }

    getLoginButton(){
        if (this.props.profile !== config.DEFAULT_PROFILE) {
            return null;
        }
        return (
            <div>
                <Button onClick={() => { this.props.actions.showLoginDialog(); }} >
                    {[
                        <span key="text">{this.props.isLoggedIn ? this.props.labels.login.logout : this.props.labels.login.title}</span>,
                        <span key="icon" className={'fa fa-user ' + (this.props.isLoggedIn ? 'connected' : '')}
                            style={{ fontSize: "1.6em", marginLeft: ".5em" }} />
                    ]}
                </Button>
            </div>
        )
    }

    renderNotice = () => {
        const { synchroFavBtnStatus, code } = this.props
        if (isUnauthorizedMode()) {
            if (synchroFavBtnStatus === SYNCHRO_FAV_STATUS.SUCCESS) {
                return (
                    <div className="favorites-synchro-notice">
                        <div>
                            <span className="synchronized">{this.props.labels.synchroFavs.favoritesPageNoticeCodeSynchronised}</span>
                            <span className="code">{code}</span>
                        </div>
                        <div>
                            <span>{this.props.labels.synchroFavs.favoritesPageNoticeExtraUnauthorizedMode}</span>
                            <span className="fa fa-refresh refresh-green"/>
                        </div>
                    </div>
                )
            }
            return (
                <div className="favorites-synchro-notice">
                    <div>
                        <span>{this.props.labels.synchroFavs.favoritesPageNoticeUnauthorizedMode}</span>
                        <span className="fa fa-refresh refresh-grey"/>
                    </div>
                    <div>
                        <span>{this.props.labels.synchroFavs.favoritesPageNoticeExtraUnauthorizedMode}</span>
                        <span className="fa fa-refresh refresh-green"/>
                    </div>
                </div>
            )
        }
        return (
            <div className="favorites-synchro-notice">
                { this.props.isLoggedIn ? this.props.labels.synchroFavs.favoritesPageNoticeLoggedIn : this.props.labels.synchroFavs.favoritesPageNotice }
                { this.getLoginButton() }
            </div>
        )
    }

    render() {
        let typedLists = [];

        console.log('favorites', this.props.favorites)
        console.log('data', this.props.data)

        if (this.props.favorites) {
            Object.keys(this.props.favorites).forEach(dataType => {
                let entries = this.props.favorites[dataType],
                    items = this.props.data[dataType];

                // Ignore this dataType if it has no data
                if (entries.length && items && items.length) {
                    const
                        count = items.length,
                        listProps = {
                            items    : items,
                            dataType : dataType,
                            favorites: entries,
                            isPending: this.props.isPending,
                            actions  : this.props.actions,
                            labels   : this.props.labels,
                            pageKey  : this.pageKey,
                            displayFavorites: true,
                        };
                    typedLists.push(
                        <TypeBar
                            key={ dataType }
                            label={ this.props.labels.data[dataType][count > 1 ? 'plural' : 'singular'] }
                            count={ count }>

                            <List { ...listProps } />
                        </TypeBar>
                    );
                }
            });
        }

        return (
            <div className="favorites-content content-font" style={{ height: this.props.height }}>

                {this.props.isSynchroFavFeatureEnabled === true && this.renderNotice()}

                { typedLists.length === 0
                    ? <NoResult labels={this.props.labels} customLabel={this.props.labels.favorites.noResults}/>

                    : <div>
                        <div className="favorites-options-container">
                            {MAP_TYPE !== "NOMAP" && <AllOnMapButton
                                labels={this.props.labels}
                                actions={this.props.actions}
                                data={stripToIds(this.props.data)} />}
                            

                            { !global.isCordovaContext && config.DOWNLOAD_FAVORITES && config.DOWNLOAD_FAVORITES.FEATURE_ENABLED && 
                                <div className="cta-btn-container">
                                    <div className="cta-btn" onClick={() => downloadFavorites(this.props.favorites)}>
                                        {this.props.labels.favorites.download}
                                    </div>
                                </div>
                            }    
                        </div>

                        <div>
                            { typedLists }
                        </div>
                      </div>
                }

                <AlertDialog {...this.codeDialogProps()}>
                    {this.renderCodeDialog()}
                </AlertDialog>
            </div>
        );
    }
}

FavoritesContent.propTypes = {
    data     : PropTypes.object,
    favorites: PropTypes.object,
    isPending: PropTypes.bool,
    isSynchroFavFeatureEnabled: PropTypes.bool,
    isLoggedIn : PropTypes.bool,
    // Common
    labels : PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.string,
    height : PropTypes.number, // prop set from AppToolbar
};

export default FavoritesContent;