
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// Conf
import { DATA_TYPE_PAPERS } from 'data/config/dataConfig';
import { PAPER_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar       from 'src/components/app-toolbar/AppToolbar';
import Menu             from 'src/components/menu/Menu';
import FetchResult      from 'src/components/fetch-result/FetchResult';
import PaperContent from './PaperContent';

import * as actions from 'src/store/actions';

import '../common-fiche.scss';
import './PaperPage.scss';

const LOG_PREF = '[PaperPage] ';


function getPageTitle(item, labels) {
    let paperName = item ? item.title : '';

    return labels.data[DATA_TYPE_PAPERS].singular + labels.common.colon + paperName;
}


class PaperPage extends Component {

    constructor(props) {
        super(props);

        this.pageKey = PAPER_PAGE_KEY;
        this.paperId = props.id;

        this.state = {};
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(getPageTitle(this.props.item, this.props.labels));
        }
    }


    componentDidMount() {
        this.fetchIfNeeded(this.props);
        this.props.actions.fetchFavorites();
    }

    componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no paper data and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        if (props.id !== this.paperId) {
            this.idHasChanged = true;
        }
        if (props.shouldFetch === true || this.idHasChanged || (!props.item && props.isPending !== true && props.itemNotFound !== true)) {
            this.paperId = props.id;
            props.actions.fetchItem(props.id, DATA_TYPE_PAPERS, [ 'cats', 'events' ]);
        }
    }

    componentDidUpdate() {
        if (this.idHasChanged === true) {
            this.idHasChanged = false;

            // child ref not available on componentDidUpdate
            window.setTimeout(this.resetScroll, 100, this);
        }
        this.setDocumentContext();
    }

    resetScroll(_this) {
        let context = _this || this;
        if (context.contentComponent && context.contentComponent.scroller) {
            context.contentComponent.scroller.scrollTo(0);
        }
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.props.labels.data[DATA_TYPE_PAPERS].singular}
                    contextualDataTypes={[DATA_TYPE_PAPERS]}
                    onSearchClear={this.props.actions.clearSearchResults}
                    searchResults={this.props.searchResults}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    <FetchResult
                        contentRef={ref => { this.contentComponent = ref; }}
                        Component={PaperContent}
                        isPending={this.props.isPending}
                        itemNotFound={this.props.itemNotFound}
                        // Content props
                        item={this.props.item}
                        isFavorite={this.props.isFavorite}
                        searchResults={this.props.searchResults}
                        favorites={ this.props.favorites }
                        favIconDisabled={ this.props.favIconDisabled }
                        // Common
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        queryString={this.props.queryString}
                        getPageTitle={getPageTitle} />

                </AppToolbar>
            </Menu>
        );
    }
};

PaperPage.propTypes = {
    id          : PropTypes.number.isRequired,
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[PAPER_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaperPage);
