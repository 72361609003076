import { SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys.js';

import SpeakerPage        from 'src/pages/speaker/SpeakerPage';
import speakerPageReducer from 'src/pages/speaker/speakerPageReducer';

export default {
    key      : SPEAKER_PAGE_KEY,
    path     : '/speaker',
    component: SpeakerPage,
    elId     : 'speaker-page',
    getReduxReducer: (state, action) => speakerPageReducer(state[SPEAKER_PAGE_KEY], action),
};
