import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EVENTS, DATA_TYPE_SPEAKERS, DATA_TYPE_PAPERS, DATA_TYPE_PAPER_CATEGORIES } from 'data/config/dataConfig'; // app modules

import { LIST_PAGE_KEY, MEDIAS_PAGE_KEY, LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';
import Banner from 'src/components/banner/Banner';
import AdBanner from 'src/components/ad-banner/AdBanner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    background: 'url(' + getUrl('files/project/home/home_background.jpg') + ') no-repeat',
    backgroundSize: 'contain'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.8,
  // rowspan
  width: 1 // colspan

}; // TEMP FIXME (use jsontosass-loader ?)

var color1 = "#005EA7";
var color2 = "#122D62";
var tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))';

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")"),
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "".concat(backgroundPosition || '50% 0%'),
        backgroundColor: "".concat(homebutton.bgcolor)
      },
      className: 'home-btn-ad'
    },
    icon: {}
  }, // Label (i18n)
  homebutton['label_' + lang], // Action to perform on click
  function (actions) {
    var url = homebutton['link_' + lang];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdBanner,
    props: {
      forcedAdKey: 'header'
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ["inbox"]
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Programme
      createItemRoute( // Style
      {
        container: {
          // style: {
          //     backgroundImage: tileBgGradient,
          // },
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Programme.svg') + ')'
          } //                                className: 'home-icon-programme',

        }
      }, // Label (i18n)
      'home.agenda', // Page & props
      LIST_PAGE_KEY, {
        inputs: [{
          dataType: DATA_TYPE_EVENT_CATEGORIES
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // Speakers
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Intervenants.svg') + ')'
          } //                                className: 'home-icon-intervenants',

        }
      }, // Label (i18n)
      'home.speakers', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_SPEAKERS
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // Abstracts
      createItemRoute( // Style
      {
        container: {
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Abstracts.svg') + ')'
          } //                              className: 'home-icon-intervenants',

        }
      }, // Label (i18n)
      'home.papers', // Page & props
      LIST_PAGE_KEY, {
        contextualTitle: "",
        inputs: [{
          dataType: DATA_TYPE_PAPER_CATEGORIES
        }]
      }, // Tile options
      GENERAL_TILE_OPTIONS), // Sponsors
      createItemRoute( // Style
      {
        container: {
          // style: {
          //     backgroundImage: tileBgGradient,
          // },
          className: 'home-btn-text'
        },
        icon: {
          style: {
            backgroundImage: 'url(' + getUrl('files/project/home/Sponsors.svg') + ')'
          } //                                className: 'home-icon-exposants',

        }
      }, // Label (i18n)
      'home.exhibitors', // Page & props
      LIST_GROUPS_PAGE_KEY, {
        contextualTitle: "",
        input: {
          dataType: DATA_TYPE_EXHIBITORS
        }
      }, // Tile options
      GENERAL_TILE_OPTIONS), //                     // Social Medias
      //                     createItemRoute(
      //                         // Style
      //                         {
      //                             container: {
      //                                 style: {
      //                                     backgroundImage: tileBgGradient,
      //                                 },
      //                                 className: 'home-btn-text',
      //                             },
      //                             icon: {
      //                                 style: {
      //                                     backgroundImage: 'url('+getUrl('files/project/home/Reseaux_sociaux.svg')+')',
      //                                 },
      // //                                className: 'home-icon-socialmedia',
      //                             },
      //                         },
      //                         // Label (i18n)
      //                         'home.medias',
      //                         // Page & props
      //                         MEDIAS_PAGE_KEY,
      //                         null,
      //                         // Tile options
      //                         GENERAL_TILE_OPTIONS,
      //                     ),
      // Social Programme
      hasAdButtons && adConfig.homebuttons.homebutton2 ? createItemAd(adConfig.homebuttons.homebutton2, lang) : null, // Twitter
      hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null]
    }
  }, {
    component: AdBanner
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @return {object}
 */
// export const get = (profile, lang, adConfig, orientation) => {
//     return getDefault(lang, adConfig);
// };


export var get = function get(profile, lang, adConfig, orientation) {
  return getDefault(lang, adConfig, orientation);
};