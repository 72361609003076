
/**
 * Persist the status of PMR has the user set it
 */
export const StoredManualPMRStatus = (function() {

    const LOCAL_STORAGE_KEY = 'pmr-status';

    const STATUS = {
        ENABLED : 'enabled',
        DISABLED: 'disabled',
    };

    function setEnabled() {
        _set(STATUS.ENABLED);
    }
    function setDisabled() {
        _set(STATUS.DISABLED);
    }
    function _set(value) {
        window.MobiGeo.Map.Route.setPMRStatusPristine(false);
        localStorage.setItem(LOCAL_STORAGE_KEY, value);
    }

    function hasBeenEnabled() {
        return localStorage.getItem(LOCAL_STORAGE_KEY) === STATUS.ENABLED;
    }

    function isPristine() {
        let isPristine = localStorage.getItem(LOCAL_STORAGE_KEY) !== STATUS.ENABLED && localStorage.getItem(LOCAL_STORAGE_KEY) !== STATUS.DISABLED;
        window.MobiGeo.Map.Route.setPMRStatusPristine(isPristine);
        return isPristine;
    }

    return { setEnabled, setDisabled, hasBeenEnabled, isPristine };
})();
