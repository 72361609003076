import _defineProperty from "/Users/Benoit_et_Let/Sites/eventime/aecongress/aecongress19/mobigeo-sdk-aecongress19/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EVENTS, DATA_TYPE_SPEAKERS, DATA_TYPE_PAPERS, DATA_TYPE_PAPER_CATEGORIES } from 'data/config/dataConfig';
import { ENV, TOU_LINK } from './config';
import { createItem, createItemLang, createItemRoute } from 'src/core/util/ConfigItemFactory';
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isIOS, isAndroid } from 'src/core/util/browser'; //import { openCurrentPageInApp } from 'src/core/util/browser';

import { HOME_PAGE_KEY, FAVORITES_PAGE_KEY, INBOX_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, SEARCH_PAGE_KEY, MEDIAS_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY, LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';
import { openUrl } from 'src/core/util/JsTools';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE,
    WEBAPP_EXH_PROFILE = _require.WEBAPP_EXH_PROFILE,
    WEBAPP_EVT_PROFILE = _require.WEBAPP_EVT_PROFILE;

var LOG_PREF = '[menuConfig] '; // @see https://onsen.io/v2/docs/react/SplitterSide.html

export var ONS_MENU_OPTIONS = {
  side: 'right',
  width: 210,
  swipeTargetWidth: '100%',
  collapse: true,
  swipeable: true
}; // see https://github.com/markusenglund/react-switch#api

export var LOCATION_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
export var PMR_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
var ICON_SIZE = '80%';
/**
 * Generate an item routing to Exhibitors page
 */
// const getItemRouteBrands = labels => createItemRoute(
//     // style:
//     { icon: {
//             style: {
//                 backgroundImage: 'url('+getUrl('files/project/home/brands.svg')+')',
//                 backgroundSize : ICON_SIZE,
//             }
//         },
//     },
//     // label:
//     labels.menu.brands,
//     // page key:
//     LIST_PAGE_KEY,
//     // page props:
//     {   locateAll: false,
//         inputs: [{ dataType: DATA_TYPE_BRAND_CATEGORIES }],
//     }
// );
// *
//  * Generate an item routing to Restaurants page
// const getItemRouteRestaurants = labels => createItemRoute(
//     // style:
//     {   icon: {
//             style: {
//                 backgroundImage: 'url('+getUrl('files/project/home/restaurants.svg')+')',
//                 backgroundSize : ICON_SIZE,
//             }
//         },
//     },
//     // label:
//     labels.menu.restaurants,
//     // page key:
//     LIST_PAGE_KEY,
//     // page props:
//     {   locateAll: false,
//         inputs: [{ dataType: DATA_TYPE_RESTAURANT_CATEGORIES }] }
// );

/**
 * Generate an item routing to Home page
 */

var getItemRouteHome = function getItemRouteHome(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-home'
    }
  }, // label:
  labels.home.title, // page key:
  HOME_PAGE_KEY);
};
/**
 * Generate an item routing to Exhibitors page
 */


var getItemRouteExhibitors = function getItemRouteExhibitors(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Sponsors.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.exhibitors, // page key:
  LIST_GROUPS_PAGE_KEY, // page props:
  {
    contextualTitle: "",
    input: {
      dataType: DATA_TYPE_EXHIBITORS
    }
  });
};
/**
 * Generate an item routing to Services page
 */


var getItemRouteServices = function getItemRouteServices(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Informations_dark.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.services, // page key:
  LIST_PAGE_KEY, // page props:
  {
    locateAll: false,
    inputs: [{
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to ListGroups page
 */


var getItemRouteAgenda = function getItemRouteAgenda(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Programme.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.agenda, // page key:
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to ListGroups page
 */


var getItemRouteSpeakers = function getItemRouteSpeakers(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Intervenants.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.speakers, // page key:
  LIST_PAGE_KEY, // page props:
  {
    contextualTitle: "",
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  });
};
/**
 * Generate an item routing to ListGroups page
 */


var getItemRoutePapers = function getItemRoutePapers(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Abstracts.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.papers, // page key:
  LIST_PAGE_KEY, // page props:
  {
    contextualTitle: "",
    inputs: [{
      dataType: DATA_TYPE_PAPER_CATEGORIES
    }]
  });
};
/**
 * Generate an item routing to Map page
 */


var getItemRouteMap = function getItemRouteMap(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/Plan_dark.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.map, // page key:
  MAP_PAGE_KEY);
};
/**
 * Generate an item routing to Search page
 */


var getItemRouteSearch = function getItemRouteSearch(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-search',
      style: {
        lineHeight: '1.3em'
      }
    }
  }, // label:
  labels.search.title, // page key:
  SEARCH_PAGE_KEY);
};
/**
 * Generate an item routing to Favorites page
 */


var getItemRouteFavorites = function getItemRouteFavorites(labels) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'icon-font ',
      webfont: 'e',
      fontSize: '0.5em'
    }
  }, // label:
  labels.favorites.title, // page key:
  FAVORITES_PAGE_KEY);
};
/**
 * Generate an item routing to Inbox page
 */


var getItemRouteInbox = function getItemRouteInbox(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/inbox.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.inbox, // page key:
  INBOX_PAGE_KEY);
};

var getItemLang = function getItemLang(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-lang bottom5',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, labels.menu.language, actions.showChooseLangDialog);
};

var getActionTOU = function getActionTOU(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-cgu bottom5',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, labels.menu.cgu, function () {
    if (openUrl(TOU_LINK[labels.id])) {
      actions.linkClicked(TOU_LINK[labels.id]);
    }
  });
};

var getItemLogin = function getItemLogin(labels, actions) {
  return createItem(null, labels.menu.login, actions.showLoginDialog);
};

var getItemProfile = function getItemProfile(labels, actions) {
  return createItem({
    icon: {
      className: 'fa fa-user',
      style: {
        fontSize: '1.5em',
        lineHeight: '1.3em'
      }
    }
  }, labels.menu.profile, actions.showProfileDialog);
};
/**
 * Generate an item routing to Choose profile page
 */


var getItemRouteChooseProfile = function getItemRouteChooseProfile(labels, profile) {
  return createItemRoute( // style:
  {
    icon: {
      className: 'fa fa-user'
    }
  }, // label:
  (profile ? '<div class="menu-text-current-profile">' + profile + '</div>' : '') + labels.menu.toggleProfile, // page key:
  CHOOSE_PROFILE_PAGE_KEY);
};

var getAdMenuButton = function getAdMenuButton(conf, labels, actions) {
  return !conf ? null : createItem( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl(conf['img_' + labels.id]) + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  conf['label_' + labels.id], // action:
  function () {
    var url = conf['link_' + labels.id];

    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
};

var getItemSocialMedia = function getItemSocialMedia(labels) {
  return createItemRoute( // style:
  {
    icon: {
      style: {
        backgroundImage: 'url(' + getUrl('files/project/home/SocialMedia_dark.svg') + ')',
        backgroundSize: ICON_SIZE
      }
    }
  }, // label:
  labels.menu.medias, // page key:
  MEDIAS_PAGE_KEY, // page props:
  null);
}; // const getItemOpenPageInNativeApp = () => createItem(
//     { icon: {
//         className: 'fab fa-'+(isIOS() ? 'apple' : 'android'),
//         style: {
//             color: '#bababa',
//             fontSize: '2em',
//         }
//     }},
//     // label:
//     'Open in '+(isIOS() ? 'iOS' : 'android')+' app',
//     // action:
//     openCurrentPageInApp,
// );

/**
 * Menu configuration
 */
// const getConfig = (actions, labels, adConfig, twoColumns) => {
//     let conf = {
//         default: [
//             getItemRouteBrands(labels),
//             getItemRouteRestaurants(labels),
//             getItemRouteServices(labels),
//             getItemRouteHours(labels),
//             getItemRouteMap(labels),
//             // Generic:
//             getItemRouteSearch(labels),
//             getItemRouteFavorites(labels),
//             getItemRouteInbox(labels),
//             getItemLang(labels, actions),
//             ENV === 'dev' && global.isCordovaContext !== true && (isIOS() || isAndroid()) ? getItemOpenPageInNativeApp() : null,
//         ]
//     };
//
//     if (adConfig && adConfig.menubuttons) {
//         Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
//             conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions) );
//         });
//     }
//
//     // Filter any empty entry
//     Object.keys(conf).forEach(function(profile) {
//         conf[profile] = conf[profile].filter(menuItemConfig => menuItemConfig);
//     });
//
//     return conf;
// };

/**
 * Menu configuration for default profile
 */


var getDefaultConfig = function getDefaultConfig(actions, labels, adConfig, profile) {
  var conf = _defineProperty({
    default: [getItemRouteHome(labels), getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteSpeakers(labels), getItemRoutePapers(labels), getItemRouteExhibitors(labels)]
  }, HOME_PAGE_KEY, [getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteSpeakers(labels), getItemRoutePapers(labels), getItemRouteExhibitors(labels)]);

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
      conf[HOME_PAGE_KEY].push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }

  conf.default.push(getItemLang(labels, actions));
  conf[HOME_PAGE_KEY].push(getItemLang(labels, actions)); // Filter any empty entry

  Object.keys(conf).forEach(function (page) {
    conf[page] = conf[page].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Menu configuration for GP profile
 */


var getWebAppExhConfig = function getWebAppExhConfig(actions, labels, adConfig, profile) {
  var conf = _defineProperty({
    default: [getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteExhibitors(labels), getItemRouteMap(labels), getItemLang(labels, actions)]
  }, HOME_PAGE_KEY, []);

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  } // Filter any empty entry


  Object.keys(conf).forEach(function (profile) {
    conf[profile] = conf[profile].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Menu configuration for PRESS profile
 */


var getWebAppEvtConfig = function getWebAppEvtConfig(actions, labels, adConfig, profile) {
  var conf = _defineProperty({
    default: [getItemRouteFavorites(labels), getItemRouteSearch(labels), getItemRouteAgenda(labels), getItemRouteMap(labels), getItemLang(labels, actions)]
  }, HOME_PAGE_KEY, []);

  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(function (menuBtnKey) {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
      conf[HOME_PAGE_KEY].push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  } // Filter any empty entry


  Object.keys(conf).forEach(function (page) {
    conf[page] = conf[page].filter(function (menuItemConfig) {
      return menuItemConfig;
    });
  });
  return conf;
};
/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */


export var get = function get() {
  var profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  var actions = arguments.length > 2 ? arguments[2] : undefined;
  var labels = arguments.length > 3 ? arguments[3] : undefined;
  var adConfig = arguments.length > 4 ? arguments[4] : undefined;
  var twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  var conf; // Get config depending on profile

  switch (profile) {
    case DEFAULT_PROFILE:
      conf = getDefaultConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case WEBAPP_EXH_PROFILE:
      conf = getWebAppExhConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    case WEBAPP_EVT_PROFILE:
      conf = getWebAppEvtConfig(actions, labels, adConfig, labels.profile[profile]);
      break;

    default:
      console.error(LOG_PREF + 'Unsupported profile', profile);
  } // Return config depending on page


  if (conf) {
    return conf[page] || conf.default;
  } // else undefined is returned

};