
//import { combineReducers } from 'redux'

// Conf
import Pages from 'src/pages/Pages';

import * as Cordova from 'src/core/cordova/cordovaReducerAndMiddleware';

import standaloneComponentsReducers from 'src/components-standalone/standalone-components-reducers';


import fetchResultReducer from 'src/components/fetch-result/fetchResultReducer';

import appToolbarReducer from 'src/components/app-toolbar/appToolbarReducer';


/**
 * Simple generic function to add a reducer
 * @param {object}   reducers (modified by reference)
 * @param {string}   key
 * @param {function} reducer
 */
function addReducer(reducers, key, reducer) {
    if (typeof reducers[key] !== 'undefined') {
        console.error('Overriding reducer ' + key);
    }
    reducers[key] = reducer;
};

export default (state = {}, action) => {
    let reducers = {};

    // Add reducer for each page
    Object.keys(Pages).forEach((pageKey) => {

        addReducer(reducers, pageKey, Pages[pageKey].getReduxReducer(state, action));
    });

    addReducer(reducers, 'Cordova', Cordova.reducer(state.Cordova, action));

    // Add standalone components reducers
    standaloneComponentsReducers.forEach(data => {
        addReducer(reducers, data.key, data.reducer(state[data.key], action));
    });
    addReducer(reducers, "fetchResult", fetchResultReducer(state["fetchResult"], action));
    addReducer(reducers, "appToolbar", appToolbarReducer(state["appToolbar"], action));
    return reducers;
};
