
import {
    AIRCRAFT_PAGE_KEY,
    EXPERIENCE_ITEM_PAGE_KEY,
    EVENT_PAGE_KEY,
    EXHIBITOR_PAGE_KEY,
    FLIGHTS_SCHEDULE_PAGE_KEY,
    HOURS_PAGE_KEY,
    NEWPRODUCT_PAGE_KEY,
    PAPER_PAGE_KEY,
    PMR_PAGE_KEY,
    RESTAURANT_PAGE_KEY,
    MONUMENT_PAGE_KEY,
    SERVICE_PAGE_KEY,
    SPEAKER_PAGE_KEY,
    DOCUNIT_PAGE_KEY
} from 'src/pages/pagesKeys';

import {
    DATA_TYPE_AIRCRAFTS,
    DATA_TYPE_ANIMATIONS,
    DATA_TYPE_EVENTS,
    DATA_TYPE_EXHIBITORS,
    DATA_TYPE_FLIGHTS_SCHEDULE,
    DATA_TYPE_INFOS,
    DATA_TYPE_NEWPRODUCTS,
    DATA_TYPE_PMRS,
    DATA_TYPE_RESTAURANTS,
    DATA_TYPE_MONUMENTS,
    DATA_TYPE_SERVICES,
    DATA_TYPE_SPEAKERS,
    DATA_TYPE_PAPERS,
    DATA_TYPE_DOCUNITS
} from 'data/config/dataConfig';


/**
 * Declare datatype ⇔ page relation
 */
export const DATA_TYPE_TO_PAGE_KEY = {

    [ DATA_TYPE_AIRCRAFTS ]       : AIRCRAFT_PAGE_KEY,
    [ DATA_TYPE_ANIMATIONS ]      : EXPERIENCE_ITEM_PAGE_KEY,
    // No brand page
    [ DATA_TYPE_EVENTS ]          : EVENT_PAGE_KEY,
    [ DATA_TYPE_EXHIBITORS ]      : EXHIBITOR_PAGE_KEY,
    [ DATA_TYPE_FLIGHTS_SCHEDULE ]: FLIGHTS_SCHEDULE_PAGE_KEY,
    [ DATA_TYPE_INFOS ]           : HOURS_PAGE_KEY,
    [ DATA_TYPE_NEWPRODUCTS ]     : NEWPRODUCT_PAGE_KEY,
    [ DATA_TYPE_PAPERS ]          : PAPER_PAGE_KEY,
    [ DATA_TYPE_PMRS ]            : PMR_PAGE_KEY,
    [ DATA_TYPE_RESTAURANTS ]     : RESTAURANT_PAGE_KEY,
    [ DATA_TYPE_MONUMENTS ]       : MONUMENT_PAGE_KEY,
    [ DATA_TYPE_SERVICES ]        : SERVICE_PAGE_KEY,
    [ DATA_TYPE_SPEAKERS ]        : SPEAKER_PAGE_KEY,
    [ DATA_TYPE_DOCUNITS ]        : DOCUNIT_PAGE_KEY,
};


/**
 * Determine related datatype from a page key
 * @param  {string} pageKey
 */
export const getDatatypeFromPage = pageKey => {
    let match = Object.keys(DATA_TYPE_TO_PAGE_KEY).filter(
        datatype => DATA_TYPE_TO_PAGE_KEY[datatype] === pageKey
    );
    if (match) {
        switch (match.length) {
            case 0: console.warn('Could not determine data type matching page: '+pageKey);
                break;

            case 1: return match[0];

            default:
                console.error('Too many data types related to page key: '+pageKey);
                break;
        }
    }
    // default: `undefined` is returned
};
global.getDatatypeFromPage = getDatatypeFromPage;
