
import ReactDOM from 'react-dom';

import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';
import {
    DATA_TYPE_INFOS,
    // CATEGORIES_DATA_TYPE,
} from 'data/config/dataConfig';

import { elementPropsGetters } from 'data/config/listConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_UPDATED,
    LANG_CHANGED,
    LISTS_FETCHED,
    PROFILE_CHANGED,
    SEARCH_PERFORMED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    WINDOW_RESIZED,
    CLEAR_SEARCH_RESULTS
} from 'src/store/actionTypes';

import STATUS from 'src/store/fetchStatuses';

import {
    configJsonLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    langChanged,
    profileChanged,
    toggleLocationStatus,
    toggleMenu,
    updateObject,
    updatePageState,
    windowResized,
    searchPerformed,
    clearSearchResults,
} from 'src/store/reducers/commons';

import { ALPHABETICAL_IF_ABOVE } from 'data/config/listConfig';

import { indexLetterGetter } from 'src/core/util/StringUtil';

import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(LIST_PAGE_KEY), {
    favorites: {},
    favIconDisabled: DISABLE_FAVORITE_ICON === true,
});


function getUsedTables(inputs) {
    if (!inputs) {
        return [];
    }
    return inputs.map(input => input.dataType)
}


/**
 * Iterate on every entry to:
 *   - identify all different first letters
 *   - group entries by their first letter
 *
 * @param {object}
 * @param {array}
 * @return {object}
 */
function _parseItems(status, items, forceNoAlphabetical) {
    let parsedItems = {};

    // loop on data types
    Object.keys(items).forEach(function parseItemsForListPage(dataType) {
        parsedItems[dataType] = {};

        let _items = items[dataType],
            data = {},
            separators,
            length;

        if (status[dataType] !== STATUS.FETCHED || !Array.isArray(_items)) {
            length = 0;
        } else {
            length = _items.length;

            let isAlphabetical = _items.length > ALPHABETICAL_IF_ABOVE && forceNoAlphabetical !== true;
                        
            if (!isAlphabetical) {
                data.all = _items;
            }
            else {
                // Spread items by their first letter
                separators = {};
                // parsedItems[dataType].separatorEls = {};
                let listElPropsGetter = elementPropsGetters(dataType, LIST_PAGE_KEY);

                function push(item, char) {
                    if (Array.isArray(data[char]) !== true) {
                        data[char] = [];

                        // Insert a separator item
                        data[char].push({
                            isSeparator: true,
                            text: char,
                            ref : instance => {
                                // parsedItems[dataType].separatorEls[char] = ReactDOM.findDOMNode(instance);
                                separators[char] = ReactDOM.findDOMNode(instance);
                            },
                        });
                    }
                    // Add item
                    data[char].push(item);
                };

                // loop on items
                _items.forEach(function(item) {
                    let char = indexLetterGetter( listElPropsGetter.text(item) );
                    push(item, char);
                });
            }
        }

        parsedItems[dataType] = {
            data,
            getSeparators: function() { return separators; },
            length,
        };
    });
    return parsedItems;
}

/**
 * Initial action for list page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _listsFetched(state, action) {
    // GR - do not handle infos type (HoursPages)
    let hasInfosType = (action.inputs || []).filter(input => input.dataType === DATA_TYPE_INFOS).length > 0;

    if (!hasInfosType) {
        let firstDataType = Object.keys(action.items)[0];

        return updateObject(state, {
            inputs     : action.inputs,
            status     : action.status,
            items      : _parseItems(action.status, action.items),
            favorites  : action.favorites,
            contextualTitle: action.header ? null : action.contextualTitle,
            header     : action.header,
            shouldFetch: false,
            hasShowOnMapButton: Array.isArray(action.items[firstDataType])
                                    && action.items[firstDataType].length > 0
                                    && state.locateAll === true,
        });
    }
    return state;
}


function _searchPerformed(state, action){
    const status = {}
    let nextState = state

    Object.keys(action.results).forEach(dataType => {
        status[dataType] = STATUS.FETCHED
    }) 

    if (action.pageKey === LIST_PAGE_KEY) {
        nextState = updateObject(state, {
            items: _parseItems(status, action.results, true),
        });
    }

    return searchPerformed(nextState, action)
}


function _toggleFavorite(state, action) {
    if (action.favListUpdated === true && getUsedTables(state.inputs).indexOf(action.dataType) !== -1) {
        return updateObject(state, { favorites: action.favorites });
    }
    return state;
};

const _allFavoritesDeleted = state => updateObject(state, { favorites: {} });


/*function _updatePageState(state, action) {
    let newState = updatePageState(state, action, LIST_PAGE_KEY);

    if (newState !== state) {
        // State has been updated. Display 'go to root category' button if:
        let firstInput = newState.inputs[0];
        newState.rootCategoryButtonInToolbar =
            // - data type is not a category type
            CATEGORIES_DATA_TYPE.indexOf(firstInput.dataType) === -1
            // - or there is a subcategory id
            || (firstInput.id !== null && typeof firstInput.id !== 'undefined' && firstInput.id !== '');
    }
    return newState
}*/


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case POLL_CONFIG_LOADED: return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return _allFavoritesDeleted(state);
        case DATA_UPDATED     : return dataUpdated(state, action, getUsedTables(state.inputs));
        case LANG_CHANGED     : return langChanged(state, action);
        case LISTS_FETCHED    : return _listsFetched(state, action);
        case PROFILE_CHANGED  : return profileChanged(state, action, LIST_PAGE_KEY, true);
        case SEARCH_PERFORMED : return _searchPerformed(state, action);
        case TOGGLE_FAVORITE  : return _toggleFavorite(state, action);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU      : return toggleMenu(state, action, LIST_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, LIST_PAGE_KEY);
        case WINDOW_RESIZED   : return windowResized(state, action);
        case CLEAR_SEARCH_RESULTS : return clearSearchResults(state);

        default: return state;
    }
};
