// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

// Conf
import { DATA_TYPE_EVENTS } from 'data/config/dataConfig';
import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar   from 'src/components/app-toolbar/AppToolbar';
import Menu         from 'src/components/menu/Menu';
import FetchResult  from 'src/components/fetch-result/FetchResult';
import EventContent from './EventContent';

import { formatLongDateTime } from 'src/core/Lang';

import * as actions from 'src/store/actions';

import '../common-fiche.scss';
import './EventPage.scss';


const LOG_PREF = '[EventPage] ';


function getPageTitle(item, labels) {
    let eventLabel = '';
    if (item) {
        eventLabel = item.title;

        // Append date if any
        let startDate = item.start_date;
        if (startDate) {
            eventLabel += ' - '+formatLongDateTime(startDate, labels.id);

            // Append start time if any
            if (item.start_time) {
                eventLabel += ' '+item.start_time;

                // Append end time if any
                if (item.end_time) {
                   eventLabel += ' '+labels.events.to+' '+item.end_time;
               }
            }
        }
    }
    return labels.data[DATA_TYPE_EVENTS].singular + labels.common.colon + eventLabel;
}


class EventPage extends Component {

    constructor(props) {
        super(props);

        this.pageKey  = EVENT_PAGE_KEY;
        this.eventId  = props.id;
        this.dataType = DATA_TYPE_EVENTS;

        this.state = {};
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(getPageTitle(this.props.item, this.props.labels));
        }
    }

    componentDidMount() {
        this.fetchIfNeeded(this.props);
        this.props.actions.fetchFavorites();
    }

    componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);

        // Auto show POI on map if:
        //  - two columns mode is enabled
        //  - for this page, configuration indicates to automatically show the POI
        //  - item has been fetched
        //  - no item had been fetched yet or `id` is different
        if (this.props.twocolumns === true
                && this.props.autoShowPoi
                && nextProps.item
                && (!this.props.item || nextProps.item.id !== this.props.item.id)) {

            this.props.actions.showOnePoiOnMap({
                id        : nextProps.item.id,
                originalId: nextProps.item.original_id,
                type: DATA_TYPE_EVENTS,
            });
        }
    }

    componentDidUpdate() {
        if (this.idHasChanged === true) {
            this.idHasChanged = false;

            // child ref not available on componentDidUpdate
            window.setTimeout(this.resetScroll, 100, this);
        }
        this.setDocumentContext();
    }

    resetScroll(_this) {
        let context = _this || this;
        if (context.contentComponent && context.contentComponent.scroller) {
            context.contentComponent.scroller.scrollTo(0);
        }
    }

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no event data and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        if (props.id !== this.eventId) {
            this.idHasChanged = true;
        }
        if (props.shouldFetch === true || this.idHasChanged || (!props.item && props.isPending !== true && props.itemNotFound !== true)) {
            this.eventId = props.id;
            props.actions.fetchItem(props.id, DATA_TYPE_EVENTS, [ 'exhibitor', 'places', 'speakers', 'moderators', 'events', 'papers']);
        }
    }

    render() {
        console.log(LOG_PREF+'render');
        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.props.labels.data[this.dataType].singular}
                    contextualDataTypes={[this.dataType]}
                    onSearchClear={this.props.actions.clearSearchResults}
                    searchResults={this.props.searchResults}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    <FetchResult
                        contentRef={ref => { this.contentComponent = ref; }}
                        Component={EventContent}
                        isPending={this.props.isPending}
                        itemNotFound={this.props.itemNotFound}
                        // Content props
                        item={this.props.item}
                        isFavorite={this.props.isFavorite}
                        isLogoFullWidth={this.props.isLogoFullWidth}
                        searchResults={this.props.searchResults}
                        favorites={ this.props.favorites }
                        favIconDisabled={ this.props.favIconDisabled }
                        // Common
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        queryString={this.props.queryString}
                        getPageTitle={getPageTitle}
                        contributions={this.props.contributions}
                        activateRefresh={this.props.activateRefresh}
                    />

                </AppToolbar>
            </Menu>
        );
    }
};


EventPage.propTypes = {
    id          : PropTypes.number.isRequired,
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    // Common page props
    autoShowPoi       : PropTypes.bool,
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    queryString       : PropTypes.string.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLogoFullWidth   : PropTypes.bool,
    isLocationEnabled : PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[EVENT_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventPage);
