/**
 * Note: This module is from old cake framework, and was named web_app.updater
 */

// Lib
import ons from 'onsenui';

// Conf
import config from 'data/config/config';

// App modules
import fetchHelper from 'src/core/util/FetchHelper';
import { get3CharsLang } from 'src/core/data-and-assets/utils';
import assetsFactory from 'src/core/data-and-assets/assetsFactory';
import bufferFactory from 'src/core/data-and-assets/bufferFactory';
import FileSystemHelper from 'src/core/data-and-assets/FileSystemHelper';
import * as Db from 'src/core/data-and-assets/Db';
import * as DataAssetsUtil from 'src/core/data-and-assets/DataAssetsUtil';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import { DATA_AUTO_UPDATE } from 'data/config/dataConfig';

import {
    get as getLabels,
    getCurrent as getCurrentLang,
} from 'src/core/Lang';

import { CONFIG_JSON_PATH } from 'src/core/config-json/ConfigJsonManager';


const LOG_PREF = '[Updater] ';


let actions, // store binded actions
    assets = null,
    buffer = null,
    inProgress = false,
    _isInitialized = false,
    _forcedCanceled = false,
    _filesToDownload,
    _filesRemoteDatas,
    readyToCommit = false,
    readyToRestart, //eslint-disable-line no-unused-vars
    _noSurprisesTimer = null,
    NEW_FILES_URL = 'manifest/phonegap?pixel_ratio=1',
    TIMEOUT = 20 * 1000, //eslint-disable-line no-unused-vars
    NO_SURPRISES_TIMEOUT = 1000 * 10 * 60,
    ASSETS_VERSION,
    ASSETS_DEFINITION_FILE;


export function setBindedActions(_actions) {
    actions = _actions;
};

export const getAssets = () => assets;

export const getAssetsDefinitionFileUrl = () => DataAssetsUtil.getUrl(ASSETS_DEFINITION_FILE);


/**
 * Get the url of the latest version of an asset
 * @param  {string} url
 */
export const getUrl = (url, forceNoCustomScheme) => {

    let returnedValue;

    let _url = (url[0] === '/') ? url : '/' + url;

    let indexParams = _url.lastIndexOf('?');
    if (indexParams !== -1) {
        _url = _url.substr(0, indexParams);
    }
    // not ready yet
    if (!assets || _isInitialized === false) {
        returnedValue = null;
    } else {
        let asset = assets.getItem(_url);
        if (!asset || !asset.version) {
            returnedValue = null;
        } else {
            // url exist in the assets and is a valid asset
            returnedValue = FileSystemHelper.getPath('V' + asset.version + _url, forceNoCustomScheme);
        }
    }
    return returnedValue ? returnedValue : url;

};


/**
 * Initialize this module
 * @param  {function} callback
 */
export const init = (callback) => {
    console.log(LOG_PREF + 'init');

    if (global.isCordovaContext !== true) {
        console.error(LOG_PREF + 'A Cordova context is needed in order to use Updater');
        return;
    }

    inProgress = false;
    _isInitialized = false;
    ASSETS_VERSION = 'global';
    // TODOLATER: why use 'js' folder ? Better to use 'offline'... but can break apps
    // change also the 'assets' task in deploy.rb
    ASSETS_DEFINITION_FILE = 'assets_' + ASSETS_VERSION + '.json';

    buffer = bufferFactory(FileSystemHelper);
    assets = assetsFactory(FileSystemHelper, getAssetsDefinitionFileUrl, getCurrentLang);

    global.assets = assets;

    buffer.init();
    assets.init(() => {
        console.log(LOG_PREF + 'initialized');
        _isInitialized = true;
        actions.updaterInitialized();
        if (callback) {
            callback();
        }
    });
};


let modalDisplayed = {};

// `what` could be: timeout / error / abord / notmodified / parsererror
const _userInteraction = (when, what) => {
    console.log(LOG_PREF + '_userInteraction ' + when);

    if (modalDisplayed[when]) {
        // Skip new modal display if already visible
        return;
    }

    if (when === 'remoteCheckUpdate') {
        // MA: maybe not a good idea, disable for now
        //WebApp.ui.display_msg(WebApp.i18n.t('no_connection','updater'), 3000);
    } else if (when === 'showCancelUpdate') {
        modalDisplayed.showCancelUpdate = true;
        ons.notification.confirm(getLabels().update.cancel_update, {
            title: getLabels().update.modalTitle,
            buttonLabels: [getLabels().common.no, getLabels().common.yes],
            callback: (status) => {
                modalDisplayed.showCancelUpdate = false;
                if (status) {
                    _forcedCanceled = true;
                    downloadRemoteEnd();
                }
            },
        });
    } else if (when === 'updateProgressBar') {
        setProgress(what);
    } else if (when === 'clearProgress') {
        clearProgress();
    } else if (when === 'askForUpdate') {
        let size_txt;
        if (what.size < 1024) {
            size_txt = what.size + getLabels().size.bytes;
        } else if (what.size < (1024 * 1024)) {
            size_txt = (Math.ceil(what.size / 1024 * 100) / 100) + getLabels().size.kilobytes;
        } else {
            size_txt = (Math.ceil(what.size / 1024 / 1024 * 100) / 100) + getLabels().size.megabytes;
        }

        
        if (DATA_AUTO_UPDATE) {
            console.log(LOG_PREF, 'Data update launched automaticaly');
            downloadRemoteInit(what);
            downloadRemoteFiles();
        } else {
            modalDisplayed.askForUpdate = true;
            ons.notification.confirm(getLabels().update.update_detected + ` (${size_txt})`, {
                title: getLabels().update.modalTitle,
                buttonLabels: [getLabels().common.no, getLabels().common.yes],
                primaryButtonIndex: 0,
                callback: (status) => {
                    modalDisplayed.askForUpdate = false;
                    if (status) {
                        console.log(LOG_PREF, 'Data update granted by user');
                        downloadRemoteInit(what);
                        // TODO: give ability to cancel update ?
                        //_userInteraction('showCancelUpdate');
                        downloadRemoteFiles();
                    } else {
                        console.log(LOG_PREF, 'Data update denied by user');
                        inProgress = false;
                    }
                },
            });
        }
    } else if (when === 'updateComplete') {
        modalDisplayed.updateComplete = true;
        actions.showNotification({
            message: getLabels().update.update_done,
            // title: getLabels().update.modalTitle,
            cb: () => { modalDisplayed.updateComplete = false; }
        });
    }
    // Never happens:
    else if (when === 'newAppVersion') {
        modalDisplayed.newAppVersion = true;
        actions.showNotification({
            message: getLabels().update.new_version_detected,
            // title: getLabels().update.modalTitle,
            cb: () => { modalDisplayed.newAppVersion = false; }
        });
    } else if (when === 'timeout') {
        modalDisplayed.timeout = true;
        actions.showNotification({
            message: getLabels().update.timeout,
            // title: getLabels().update.modalTitle,
            cb: () => { modalDisplayed.timeout = false; },
            level: NotificationLevels.WARNING,
        });
    }
};

const beginUpdate = (datas) => {
    console.log(LOG_PREF + 'beginUpdate');
    _userInteraction('askForUpdate', datas);
};

const buildRessourcesInfos = () => {
    console.log(LOG_PREF + 'buildRessourcesInfos');

    let allAssets = assets.getItems();
    for (let file in buffer.list) {
        if (buffer.list.hasOwnProperty(file) === false) {
            continue;
        }

        allAssets[file] = buffer.list[file];
    }
    return allAssets;
};


/**
 * Launch update process
 */
export const startUpdate = () => {

    if (config.UPDATE_ENABLED !== true) {
        return;
    }

    console.log(LOG_PREF + 'startUpdate');

    if (_isInitialized === false) {
        console.log(LOG_PREF + 'startUpdate: please initialize Updater first.');
        return false;
    }

    if (inProgress === true) {
        console.log(LOG_PREF + 'startUpdate: already in progress.');
        return false;
    }
    inProgress = true;

    // MA: force inProgress to false after 10 min... because popup could be erased
    clearTimeout(_noSurprisesTimer);
    _noSurprisesTimer = setTimeout(() => {
        inProgress = false;
    }, NO_SURPRISES_TIMEOUT);

    let ressources = buildRessourcesInfos();
    remoteCheckUpdate(ressources, beginUpdate);

    return true;
};

function remoteCheckUpdate(ressources, callback) {
    console.log(LOG_PREF + 'remoteCheckUpdate');

    let params = {
        db_version: Db.getVersion(),
        db_schema: Db.getSchema(),
        files: JSON.stringify(ressources),
        locale: get3CharsLang(getCurrentLang()),
        assets_version: ASSETS_VERSION,
        localVersion: assets.getListVersion()
    };

    // convert params as form data (expected by manifest controller)
    let formData = new FormData();
    for (let key in params) {
        if (params.hasOwnProperty(key) === true) {
            formData.append(key, params[key]);
        }
    }

    fetchHelper(DataAssetsUtil.getUrl(NEW_FILES_URL + '&force=1&time=' + new Date().getTime(), true), {
            body: formData,
            method: 'POST',
        },
        true, // json
        (datas) => {
            try {
                console.info(LOG_PREF + 'update response:', datas);

                if (datas.NEW_VERSION) {
                    _userInteraction('newAppVersion');
                    inProgress = false;
                } else if (datas.nb_files === 0 && datas.data === false) {
                    // Up to date
                    assets.updateVersion(datas.version);
                    inProgress = false;
                } else if (typeof callback === 'function') {
                    // Update needed + callback provided
                    callback(datas);
                } else {
                    // Update needed but no callback provided
                    inProgress = false;
                }
            } catch (e) {
                console.error(LOG_PREF + 'Updater error while processing incoming datas.', datas);
                inProgress = false;
            }
        },
        (...args) => {
            console.error(LOG_PREF + 'remoteCheckUpdate error', args);
            _userInteraction('remoteCheckUpdate', args);
            inProgress = false;
        },
        // No modal on error
        false
    );
};

function downloadRemoteInit(datas) {
    console.log(LOG_PREF + 'downloadRemoteInit');

    // Inform app that data/assets update is about to start (to show a loader for instance)
    actions.dataUpdateStarted();

    readyToCommit = false;
    _forcedCanceled = false;
    _filesToDownload = [];
    _filesRemoteDatas = datas;
    if (datas.nb_files !== 0) {
        for (let file in datas.files) {
            if (datas.files.hasOwnProperty(file) === false) {
                continue;
            }
            _filesToDownload.push(file);
        }
    }
};

function downloadRemoteEnd() {
    console.log(LOG_PREF + 'downloadRemoteEnd');
    _userInteraction('clearProgress');
    _filesToDownload = [];
    _filesRemoteDatas = [];
    inProgress = false;
};

function downloadRemoteFiles() {
    console.log(LOG_PREF + 'downloadRemoteFiles');
    // if user canceled the update
    if (inProgress === false) {
        console.log(LOG_PREF + 'User canceled already, so just quit.');
        return;
    }

    if (_filesToDownload.length === 0) {
        console.log(LOG_PREF + 'All files have been downloaded !!');
        // user cannot cancel anymore
        _userInteraction('clearProgress');
        readyToCommit = true;
        readyToRestart = false;
        commitNewVersion();
        return;
    }

    let fileName = _filesToDownload[0];
    let fileDatas = _filesRemoteDatas.files[fileName];

    const win = () => {
        // remove successfully downloaded file
        _filesToDownload.shift();

        let percent = (_filesRemoteDatas.nb_files - _filesToDownload.length) / _filesRemoteDatas.nb_files * 100;
        _userInteraction('updateProgressBar', percent);

        downloadRemoteFiles();
    };
    const fail = (errorType, errorCode) => {
        console.log(LOG_PREF + 'Error while downloading file: ' + errorType + ' => ' + errorCode);
        // display a message in all types of error
        if (_forcedCanceled === false) {
            _userInteraction('timeout');
        }
        // for now, quit at the first error
        downloadRemoteEnd();
    };

    buffer.downloadRemoteFile(fileName, fileDatas, win, fail);
};

function commitNewVersion() {
    console.log(LOG_PREF + 'commitNewVersion');
    if (readyToCommit !== true) {
        console.log(LOG_PREF + 'Bad context');
        // cancel everything
        downloadRemoteEnd();
        return;
    }

    let hasDataToUpdate = false;
    let hasFilesToUpdate = false;

    const checkIsAllUpdated = () => {
        console.log(LOG_PREF + 'checkIsAllUpdated');

        hasDataToUpdate = (_filesRemoteDatas.data !== false);
        hasFilesToUpdate = (_filesRemoteDatas.nb_files > 0);

        let isFinished = !hasDataToUpdate && !hasFilesToUpdate;
        console.log(LOG_PREF + 'hasDataToUpdate: ' + hasDataToUpdate + ' / hasFilesToUpdate: ' + hasFilesToUpdate);

        if (isFinished === true) {
            inProgress = false;
            console.log(LOG_PREF + 'Update is applied!');
            _userInteraction('updateComplete');
            readyToRestart = true;
        }
    };

    checkIsAllUpdated();

    if (hasFilesToUpdate === true) {
        console.log(LOG_PREF + 'updating files!');
        buffer.commitBuffer(assets.version, (success) => {
            console.log(LOG_PREF + 'commitBuffer done!', success);
            if (!success) {
                // cancel if error
                downloadRemoteEnd();
            } else {
                assets.updateAssets(buffer);
                _filesRemoteDatas.nb_files = 0;
                checkIsAllUpdated();
            }
            buffer.clearList();
        });
    }

    if (hasDataToUpdate === true) {
        console.log(LOG_PREF + 'updating db!');
        Db.refresh(() => {
            _filesRemoteDatas.data = false;
            checkIsAllUpdated();
        });
    }

    assets.updateVersion(_filesRemoteDatas.version);

    if (_filesRemoteDatas.files) {
        // on old devices, a delay is needed or MobiGeo won't get the last version of assets yet
        let updatedAssets = Object.keys(_filesRemoteDatas.files);
        window.setTimeout(() => {
            detectMapAssetUpdate(updatedAssets);
            detectConfigUpdate(updatedAssets);
        }, 1000);
    }
};

/**
 * After new assets have been commited, detect if map assets have been updated.
 * This allows to determine if MobiGeo needs to be restarted.
 * @param {array} assets
 */
function detectMapAssetUpdate(assets) {
    let mapAssets = assets.filter((asset) => asset.indexOf('files/maps/') !== -1);
    if (mapAssets.length > 0) {
        console.info(LOG_PREF + 'Map asset update detected');
        actions.mapAssetUpdated(mapAssets);
    } else {
        console.log(LOG_PREF + 'No map asset update detected');
    }
};

/**
 * After new assets have been commited, detect if ad config has been updated.
 * @param {array} assets
 */
function detectConfigUpdate(assets) {
    if (assets.indexOf(CONFIG_JSON_PATH) !== -1) {
        console.info(LOG_PREF + 'Ad config update detected');
        actions.configJsonUpdated();
        actions.pollConfigUpdated();
    } else {
        console.log(LOG_PREF + 'No ad config update detected');
    }
};


function setProgress(percent) {
    console.log(LOG_PREF + `setProgress ${percent}%`);
};

function clearProgress() {
    console.log(LOG_PREF + 'clearProgress');
    actions.hideFullLoader();
};



/**
 * Delete information stored in localstorage
 */
export const clearLocalStorage = () => {
    console.log(LOG_PREF + 'clearLocalStorage');
    window.localStorage.removeItem('Assets.version');
    window.localStorage.removeItem('Assets.listVersion');
};